import Logo from 'assets/logo.png';
import { FaFacebook } from 'react-icons/fa';

const Footer = () => {
    return (
        <>
            <footer className="text-white body-font bg-neutral-800 py-8">
                <div className="container lg:max-w-5xl px-5 py-8 mx-auto flex items-center md:flex-row flex-col">
                    <div className="flex title-font font-medium items-center text-gray-900 mb-0">
                        <img src={Logo} className="w-8 h-8" alt="La Neviera Logo"/>
                        <span className="ml-3 text-sm font-bold uppercase tracking-tight text-white">Ristorante La Neviera</span>
                    </div>

                    <p className="text-sm sm:ml-4 sm:pl-4 md:border-l-2 md:border-gray-200 sm:py-2 sm:mt-0 mt-4">
                        © 2022 — Made with ❤️ by
                        <a href="https://thelicato.io" className="ml-1 underline" rel="noopener noreferrer" target="_blank">Angelo Delicato</a> - 
                        P.IVA 03628980611
                    </p>

                    <span className="inline-flex md:ml-auto mt-4 justify-center md:justify-start">
                        <a className="ml-3 text-gray-500 hover:text-white transition-colors duration-300" href="https://facebook.com/laneviera" target="_blank" rel="noopener noreferrer">
                            <FaFacebook size={24}/>
                        </a>
                    </span>
                </div>
            </footer>
        </>
    )
}

export default Footer;